import { type JSX } from "solid-js";

interface Props {
  children: JSX.Element;
}

export function PreviewPane(props: Props) {
  return (
    <aside class="rounded-md p-5 border border-neutral-200/40">
      <div class="rounded-sm overflow-hidden">{props.children}</div>
    </aside>
  );
}
