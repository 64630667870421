import { createSignal } from "solid-js";
import { Dynamic } from "solid-js/web";
import { WelcomeEmail } from "~/components/email/welcome";
import { PreviewPane } from "~/components/preview-email";
import {
  TextField,
  TextFieldInput,
  TextFieldTextArea,
} from "~/components/solid-ui/text-field";

export default function Template() {
  const [testUser, setTestUser] = createSignal("{{ test user }}");
  const [infoBlock, setInfoblock] = createSignal("Text area block");
  return (
    <section class="flex justify-around items-center">
      <div class="h-full">
        <h1>Welcome Email</h1>
        <div>
          <ul>
            <li>
              Test user:
              <TextField>
                <TextFieldInput
                  type="text"
                  id="text-user"
                  name="text-user"
                  placeholder="{{ user name }}"
                  value={testUser()}
                  onInput={(evt) => {
                    setTestUser(evt.currentTarget.value);
                  }}
                />
              </TextField>
            </li>
            <li>
              Intro block:
              <TextField>
                <TextFieldTextArea
                  id="info-block"
                  name="info-block"
                  placeholder="Michael Scott"
                  value={infoBlock()}
                  onInput={(evt) => {
                    setInfoblock(evt.currentTarget.value);
                  }}
                />
              </TextField>
            </li>
          </ul>
        </div>
      </div>
      <PreviewPane>
        <Dynamic
          component={() => (
            <WelcomeEmail token={"fake-token"} name={testUser()} />
          )}
        />
      </PreviewPane>
    </section>
  );
}
